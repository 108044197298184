<template>
  <div id="access" class="bg-main_bg flex-row lg:flex py-[100px]">

    <div class="schedule_title text-center w-full mx-auto">

      <div class="text-center xs:w-[60%] lg:w-[40%] m-auto">
        <div class="flex items-center space-x-5">
          <div class="flex-1 border-b-4 border-main"></div>
          <span class="text-4xl text-base_c">Schedule</span>
          <div class="flex-1 border-b-4 border-main"></div>
        </div>
      </div>

      <div class="schedule-img pt-[50px]">
        <Carousel :wrapAround="true" :transition="1500">
          <Slide v-for="slide in slides" :key="slide.index">
            <div>
              <img :src="getImgUrl(slide.img)"
                    class="object-contain w-[80%] mx-auto rounded-lg">
            </div>
          </Slide>
          <template #addons>
            <Navigation class=""/>
            <Pagination class="text-base_c"/>
          </template>
        </Carousel>
      </div>
    </div>

    <div class="sm:pt-[40px] lg:pt-0 text-center w-full mx-auto">

      <div class="access_title text-center xs:w-[60%] lg:w-[40%] m-auto">
        <div class="flex items-center space-x-5">
          <div class="flex-1 border-b-4 border-main"></div>
          <span class="text-4xl text-base_c">Access</span>
          <div class="flex-1 border-b-4 border-main"></div>
        </div>
      </div>

      <div class="pt-[50px] w-full">
        <div class="access-img">
          <iframe class="xs:w-[340px] xs:h-[300px] xs:mx-auto
                        md:w-[700px] md:h-[400px]
                        lg:w-[550px] lg:h-[300px]" 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1854.8744071666097!2d139.47938796092382!3d35.72281026741525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018e68451aa02a9%3A0x7204867a1e7faa98!2z44CSMTg3LTAwNDMg5p2x5Lqs6YO95bCP5bmz5biC5a2m5ZyS5p2x55S677yR5LiB55uu77yX4oiS77yR!5e0!3m2!1sja!2sjp!4v1712647892212!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="xs:pt-[3%] lg:pl-[3%] w-[80%] xs:mx-auto">
          <p class="text-start py-2 text-base_c">
            住所 : 東京都小平市学園東町１−７−１ 黒木ビル1F
          </p> <hr>
          <p class="text-start py-2 text-base_c">
            電話 : 042-508-2186 *現在はご使用頂けません
          </p> <hr>
        </div>
      </div> 
    </div>

  </div>
</template>

<script setup>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

const slides = [
  { index: 0, img: "cal_5.png" },
  { index: 1, img: "cal_6.png" },
]

const getImgUrl = (filename) => {
    return process.env.BASE_URL + "Carendar/" + filename;
  };


import { onMounted } from 'vue'
import ScrollReveal from 'scrollreveal'

const sr = ScrollReveal()

onMounted(() => {
  const schedule_title = document.querySelector('.schedule_title')
  const access_title = document.querySelector('.access_title')
  const schedule_img = document.querySelector('.schedule-img')
  const access_img = document.querySelector('.access-img')

  sr.reveal(schedule_title, {
    reset: false,
    origin: 'bottom',
    distance: '20px',
    delay: 100,
    duration: 2000,
  })
  sr.reveal(access_title, {
    reset: false,
    origin: 'bottom',
    distance: '20px',
    delay: 100,
    duration: 2000,
  })
  sr.reveal(schedule_img, {
    reset: false,
    origin: 'bottom',
    distance: '20px',
    delay: 200,
    duration: 2000,
  })
  sr.reveal(access_img, {
    reset: false,
    origin: 'bottom',
    distance: '20px',
    delay: 200,
    duration: 2000,
  })
})

</script>

<style>
.carousel__prev,
.carousel__next {
  color: #dfdede;
}

</style>
