<template>
  <div class="bg-main_bg text-base_c w-full">
    <div class="px-[5%]  justify-between md:flex md:grid-col-3">
      <div class="xs:w-full md:w-[25%]">
        <router-link to="/">
          <img :src="require('/src/assets/img/footer_logo.svg')"
              class="relative object-contain xs:w-[70%] md:w-[80%] xs:m-auto md:ml-0">  
        </router-link>
      </div>

      <div class="xs:w-full md:w-[50%] md:pt-[8%] text-xl">
        <div class="pb-[5px] md:flex md:px-[30px] justify-between">
          <router-link class="hover:text-accent" to="#about">
            <p>About</p>
          </router-link>            
          <!-- <router-link class="hover:text-accent" to="#news">
            <p>News</p>
          </router-link>             -->
          <router-link class="hover:text-accent" to="#item">
            <p>Item</p> 
          </router-link>            
          <router-link class="hover:text-accent" to="#access">
            <p>Access</p> 
          </router-link>            
          <router-link class="hover:text-accent" to="#sns">
            <p>SNS</p>
          </router-link>                       
        </div>
      <hr>
        <div class="md:flex justify-center pt-[20px] text-[16px]">
          <router-link class="hover:text-accent" to="form">
            <p>お問い合わせフォーム</p> 
          </router-link>
          <router-link class="hover:text-accent pl-[10%]" to="privacy">
            <p>プライバシーポリシー</p> 
          </router-link>
        </div>
      </div>

      <div class="xs:w-full md:w-[25%] pt-[8%] xs:pb-[30px] md:pb-0">
        <div class="icon w-[50%] m-auto flex justify-between">
          <div class="w-[25%]">
            <router-link to="/instagram" target="_blank">
              <img :src="require('/src/assets/img/IG-3.svg')"
                  class="object-contain">  
            </router-link>
          </div>
          <div class="w-[25%]">
            <router-link to="#">
              <img :src="require('/src/assets/img/line_log.png')"
                  class="object-contain rounded">
            </router-link>
          </div>
        </div>
        
        <div class="pt-[10%] text-center">
          <small>&copy; 2024 Le Pain Doré</small>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>

</script>

<style scoped>
</style>
