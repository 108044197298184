<template>
  <div id="item" class="py-[100px]"> 
    <div class="pb-[50px] text-center xs:w-[60%] lg:w-[40%] m-auto">
      <div class="item-title flex items-center space-x-5">
        <div class="flex-1 border-b-4 border-gray-600"></div>
          <span class="text-4xl text-gray-600">Item</span>
        <div class="flex-1 border-b-4 border-gray-600"></div>
      </div>
    </div>

    <div class="pt-[20px] justify-between xs:w-[90%] md:w-[80%] m-auto">
      
      <div class="item-list relative grid bg-item_bg rounded-xl
                xs:grid-cols-2 xs:grid-rows-5 md:grid-cols-3 md:grid-rows-4 lg:grid-cols-5 lg:grid-rows-2">

<!-- image 1 -->
        <div class="item_img_1 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/1.png')"
                class="item-tips object-contain rounded-md absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_1.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>

<!-- image 2 -->
        <div class="item_img_2 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/2.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_2.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 3 -->
        <div class="item_img_3 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/3.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_3.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 4 -->
        <div class="item_img_4 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/4.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_4.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 5 -->
        <div class="item_img_5 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/5.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_5.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 6 -->
        <div class="item_img_6 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/6.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_6.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 7 -->
        <div class="item_img_7 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/7.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_7.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 8 -->
        <div class="item_img_8 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/8.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_8.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 9 -->
        <div class="item_img_9 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/9.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_9.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
<!-- image 10 -->
        <div class="item_img_10 border-b-4 border-gray-500">
          <span class="relative group">
            <!-- tong -->
            <span>
              <img :src="require('/src/assets/img/item/tong.svg')"
                class="object-contain absolute opacity-0 w-[40%] -translate-y-[30%] translate-x-[150%] transform rotate-[25deg] group-hover:opacity-100 group-hover:-translate-y-[50%] transition duration-1000 delay-200">  
            </span>
            <!-- tips -->
            <span>
              <img :src="require('/src/assets/img/item/10.png')"
                class="item-tips object-contain rounded-lg absolute opacity-0 w-[100%] translate-x-[0%] translate-y-[150%] group-hover:opacity-100 transition duration-500 delay-200">  
            </span>
            <!-- img -->
            <img :src="require('/src/assets/img/item/item_10.svg')"
                class="object-contain rounded-xl transition duration-1000 delay-200 hover:-translate-y-[10%]">  
          </span>
        </div>
      </div>    
    </div>
  </div>

</template>

<script setup>
import { onMounted,  } from 'vue'
import ScrollReveal from 'scrollreveal'

const sr = ScrollReveal()

onMounted(() => {
  const item_title = document.querySelector('.item-title')
  const item_img_1 = document.querySelector('.item_img_1')
  const item_img_2 = document.querySelector('.item_img_2')
  const item_img_3 = document.querySelector('.item_img_3')
  const item_img_4 = document.querySelector('.item_img_4')
  const item_img_5 = document.querySelector('.item_img_5')
  const item_img_6 = document.querySelector('.item_img_6')
  const item_img_7 = document.querySelector('.item_img_7')
  const item_img_8 = document.querySelector('.item_img_8')
  const item_img_9 = document.querySelector('.item_img_9')
  const item_img_10 = document.querySelector('.item_img_10')

  sr.reveal(item_title, {
    reset: false,
    origin: 'right',
    delay: 100,
    duration: 2000,
    scale: 1,
    distance: '100px',
    mobile: false,
  })
  sr.reveal([item_img_1, item_img_6], {
    reset: false,
    delay: 200,
    duration: 1000,
    scale: 1,
    distance: '20px'
  })
  sr.reveal([item_img_2, item_img_7], {
    reset: false,
    delay: 300,
    duration: 1000,
    scale: 1,
    distance: '20px'
  })
  sr.reveal([item_img_3, item_img_8], {
    reset: false,
    delay: 400,
    duration: 1000,
    scale: 1,
    distance: '20px'
  })
  sr.reveal([item_img_4, item_img_9], {
    reset: false,
    delay: 500,
    duration: 1000,
    scale: 1,
    distance: '20px'
  })
  sr.reveal([item_img_5, item_img_10], {
    reset: false,
    delay: 500,
    duration: 1000,
    scale: 1,
    distance: '20px'
  })
})

</script>

<style scoped>
.item-bg {
  z-index: 0;
}

 .item-list {
  z-index: 3;
} 
.item-border {
  z-index: 2;
}
.item-tips {
  z-index: 4;
}
</style>