<template>
  <div class="about_title xs:pt-[100px] lg:pt-[150px] pb-[100px]"> 

    <div class="text-center xs:w-[60%] lg:w-[40%] m-auto">
      <div class="flex items-center space-x-5">
        <div class="flex-1 border-b-4 border-gray-600"></div>
        <span class="text-4xl text-gray-600">About</span>
        <div class="flex-1 border-b-4 border-gray-600"></div>
      </div>
    </div>
    
    <div 
      class="pt-[50px] m-auto xs:w-full lg:w-[60%]">
      <div class="img">
        <img :src="require('/src/assets/img/about_img.png')"
            class="object-contain px-4">  
      </div>
    </div>
  </div>
</template>

<script setup>
// import { onMounted, ref } from 'vue'
// import ScrollReveal from 'scrollreveal'

// const title = ref(null)
// const img = ref(null)

// onMounted(() => {
//   const sr = ScrollReveal()

//   sr.reveal(title.value, {
//     reset: false,
//     delay: 0,
//     duration: 2000,
//     mobile: false,
//   })
//   sr.reveal(img.value, {
//     reset: false,
//     delay: 200,
//     duration: 2000,
//   })
// })
import { onMounted, } from 'vue'
import ScrollReveal from 'scrollreveal'

const sr = ScrollReveal();

onMounted(() => {
  const about_title = document.querySelector('.about_title')
  const about_img = document.querySelector('.about_img')

  sr.reveal(about_title, {
    reset: false,
    delay: 100,
    duration: 2000,
    mobile: false,
  })
  sr.reveal(about_img, {
    reset: false,
    delay: 200,
    duration: 2000,
  })
})
</script>
