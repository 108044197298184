<template>
  <div>
    <iframe
      class="mx-auto py-[150px] w-full" 
      src="https://docs.google.com/forms/d/e/1FAIpQLSeFXWwJUuF9YySG8W6mpgNJdyvs1cf6RUcYRiLo1MfnfftYTw/viewform?embedded=true" width="640" height="821" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
    <!-- <iframe 
      class="mx-auto py-[150px] w-full" 
      src="https://docs.google.com/forms/d/e/1FAIpQLSc2kW4teIf2vvArggtAgd12PlzFEhOXuumNTXYBNEfa_R5C4Q/viewform?embedded=true" width="640" height="856" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe> -->
  </div>  
</template>

<script setup>
</script>
