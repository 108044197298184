<template>
  <div id="sns" class="py-[100px]">

    <div class="title xs:w-[60%] md:w-[40%] m-auto flex items-center space-x-5">
      <img :src="require('/src/assets/img/follow.svg')"
              class="object-contain h-[150px] mx-auto">
    </div>
    


    <div class="icon xs:pt-[50px] md:pt-[80px] xs:w-[60%] md:w-[40%] m-auto flex justify-between">
      <div class="xs:w-[40%] md:w-[25%]">
        <router-link to="/instagram" target="_blank">
          <img :src="require('/src/assets/img/ig_log.svg')"
              class="object-contain rounded-xl
                transition hover:scale-110">
          <p class="text-center text-gray-600 text-[24px]">Instagram</p>        
        </router-link>
      </div>
      <div class="xs:w-[40%] md:w-[25%]">
        <router-link to="#">
          <img :src="require('/src/assets/img/line_log.png')"
              class="object-contain rounded-xl
                transition hover:scale-110">
          <p class="text-center text-gray-600 text-lg"></p>        
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import ScrollReveal from 'scrollreveal'

const sr = ScrollReveal();

onMounted(() => {
  const title = document.querySelector('.title')
  const icon = document.querySelector('.icon')

  sr.reveal(title, {
    reset: false,
    delay: 100,
    duration: 2000,
    mobile: false,
  })
  sr.reveal(icon, {
    reset: false,
    origin: 'bottom',
    delay: 100,
    duration: 1500,
    distance: '100px',
    mobile: false,
  })

})
</script>

<style lang="scss" scoped>

</style>