<template>
  <div class="navbar xs:invisible md:visible fixed w-full xs:h-[50px] md:h-[100px] flex justify-between bg-main_bg bg-opacity-75">
    <!-- pc -->
    <div>
      <router-link to="/">
        <img :src="require('/src/assets/img/nav_logo.svg')" 
              alt="log"
              class="w-[120%] h-[120%] -ml-[20px] -mt-[10px]">
      </router-link>
    </div>

    <div class="h-full text-lg flex justify-strech pb-[10px]">
      <router-link to="/#about">
        <p class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
          relative cursor-pointer transition-all duration-500
          before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
          before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
          hover:before:w-[60%] hover:before:opacity-100"
        >
        About
        </p>
      </router-link>
      <!-- <router-link to="/#news">
        <p class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
          relative cursor-pointer transition-all duration-500
          before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
          before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
          hover:before:w-[60%] hover:before:opacity-100"
        >
        News
        </p>
      </router-link> -->
      <router-link to="/#item">
        <p class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
          relative cursor-pointer transition-all duration-500
          before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
          before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
          hover:before:w-[60%] hover:before:opacity-100"
        >
        Item
        </p>
      </router-link>
      <router-link to="/#access">
        <p class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
          relative cursor-pointer transition-all duration-500
          before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
          before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
          hover:before:w-[60%] hover:before:opacity-100"
        >
        Access
        </p>
      </router-link>
      <router-link to="/#sns">
        <p class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
          relative cursor-pointer transition-all duration-500
          before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
          before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
          hover:before:w-[60%] hover:before:opacity-100"
        >
        Follow
        </p>
      </router-link>
    </div>
  
<!-- humberger -->
  <div class="fixed xs:visible md:invisible w-full h-[80px] bg-main_bg opacity-75">
    <div class="">
      <router-link to="/">
        <img :src="require('/src/assets/img/nav_logo.svg')" 
              alt="log"
              class="w-[50%] h-[50%] mt-[15px]">
      </router-link>
    </div>

    <div class="xs:visible md:invisible w-full flex justify-end -mt-[50px] -ml-[20px]">    
      <button class="fa-bars hover:opacity-50" @click="toggleMenu" v-if="!isMenuOpen">
        <font-awesome-icon :icon="['fas', 'bars']"/>
      </button>
      <button class="fa-x hover:opacity-50" @click="closeMenu" v-else>
        <font-awesome-icon :icon="['fas', 'x']" />
      </button>
    </div>

    <div v-show="isMenuOpen" class="w-[35%] ml-[65%] -mt-[40px] bg-black bg-opacity-25">
      <ul class="pt-[40px] text-2xl">
        <router-link to="/#about">
          <li class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
            relative cursor-pointer transition-all duration-500
            before:content-[''] before:absolute before:bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
            before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
            hover:before:w-[80%] hover:before:opacity-100 hover:bg-main_bg"
          >
          About
          </li>
        </router-link>
        <!-- <router-link to="/#news">
          <li class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
            relative cursor-pointer transition-all duration-500
            before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
            before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
            hover:before:w-[80%] hover:before:opacity-100 hover:bg-main_bg"
          >
          News
          </li>
        </router-link> -->
        <router-link to="/#item">
          <li class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
            relative cursor-pointer transition-all duration-500
            before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
            before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
            hover:before:w-[80%] hover:before:opacity-100 hover:bg-main_bg"
          >
          Item
          </li>
        </router-link>
        <router-link to="/#access">
          <li class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
            relative cursor-pointer transition-all duration-500
            before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
            before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
            hover:before:w-[80%] hover:before:opacity-100 hover:bg-main_bg"
          >
          Access
          </li>
        </router-link>
        <router-link to="/#sns">
          <li class="h-full flex items-end px-6 pb-2 text-base_c hover:text-accent
            relative cursor-pointer transition-all duration-500
            before:content-[''] before:absolute before:-bottom before:left-1/2 before:-translate-x-1/2 before:w-0 before:h-0.5
            before:rounded-full before:opacity-0 before:transition-all before:duration-500 before:bg-accent
            hover:before:w-[80%] hover:before:opacity-100 hover:bg-main_bg"
          >
          Follow
          </li>
        </router-link>
      </ul>
    </div>
  </div>
  
</div>


</template>

<script setup>
import { ref } from 'vue'

const isMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const closeMenu = () => {
  isMenuOpen.value = false
}

</script>

<style scoped>
.navbar {
  z-index: 100;
}
.fa-bars {
  width: 40px;
  height: 40px;
}

.fa-x {
  width: 30px;
  height: 30px;
}
</style>
