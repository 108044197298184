import { createRouter, createWebHashHistory } from 'vue-router'
import TheMain from '@/views/main/TheMain';
import PrivacyPage from '@/views/main/PrivacyPage';
import TheForm from '@/views/main/TheForm';

const routes = [
  {
    path: '/',
    name: 'TheMain',
    component: TheMain,
    meta: { title: 'Le Pain Doré | ル・パン・ドレ' }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage,
    meta: { title: 'プライバシーポリシー | Le Pain Doré' }
  },
  {
    path: '/form',
    name: 'TheForm',
    component: TheForm,
    meta: { title: 'お問い合わせ | Le Pain Doré' }
  },
  {
    path: '/instagram',
    name: 'instagram',
    beforeEnter() {
      window.location = "https://instagram.com/boulangerie_le_pain_dore/"
    }
  }
]

const DEFAULT_TITLE = 'Le Pain Doré';
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'example-active',
  linkExactActiveClass: 'example-exact-active',
  scrollBehavior(to) {
    if(to.hash) {
      return {
        el: to.hash, behavior: 'smooth'
      };
    } else {
      return {
        top: 0, behavior: 'smooth'
      };
    }
  }
})
router.afterEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE
})

export default router