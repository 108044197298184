<template>
  <div class="xs:pt-[120px] xs:px-[50px] xs:pb-[50px]
              md:pt-[200px] md:px-[100px] md:pb-[100px]">

    <div class="text-4xl text-center">プライバシーポリシー</div>
    <div class="pt-[50px]">
      <h2 class="text-2xl">基本方針</h2>
      <p class="pt-[20px]">当サイトは、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、当サイトで取り扱う個人情報の取得、利用、管理を適正に行います。</p>

      <h2 class="pt-[50px] text-2xl">適用範囲</h2>
      <p class="pt-[20px]">本プライバシーポリシーは、お客様の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。</p>

      <h2 class="pt-[50px] text-2xl">個人情報の利用目的</h2>
      <p class="pt-[20px]">当サイトは、お客様からご提供いただく情報を以下の目的の本意内において利用します。</p>
      <ul class="pt-[20px]">
        <li>・各種お問い合わせへの対応、お客様からのお申込みいただいたサービス等の提供のため。</li>
        <li>・当サイトが実施するアンケートへのご協力のお願いのため。</li>
        <li>・当サイトのサービス向上・改善、新サービスを検討するための分析等を行うため。</li>
        <li>・個人を識別できない形で統計データを作成し、当サイトおよびお客様の参考資料とするため。</li>
      </ul>

      <h2 class="pt-[50px] text-2xl">個人情報の管理</h2>
      <p class="pt-[20px]">お客様からお預かりした個人情報は、不正アクセス、紛失、漏えい等が怒らないよう、慎重かつ適切に管理します。</p>

      <h2 class="pt-[50px] text-2xl">個人情報の第三者提供</h2>
      <p class="pt-[20px]">当サイトは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。</p>
      <ul class="pt-[20px]">
        <li>・ご本人の同意がある場合 法令に基づく場合</li>
        <li>・人の生命・身体・財産の保護に必要な場合</li>
        <li>・公衆衛生・児童の健全育成に必要な場合 国の機関等の法令の定める事務への協力の場合（税務調査、統計調査）</li>
      </ul>
      <p class="pt-[20px]">当サイトでは、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。</p>

      <h2 class="pt-[50px] text-2xl">個人情報に関するお問い合わせ</h2>
      <p class="pt-[20px]">開示、訂正、利用停止等のお申し出があった場合には、所定の方法に基づき対応いたします。具体的な方法については、個別にご案内しますので、お問い合わせください。</p>

      <h2 class="pt-[50px] text-2xl">アクセス解析</h2>
      <p class="pt-[20px]">(1)Cookieについて</p>
      <p class="pl-[20px]">Cookieとは、ユーザーが対象サービスにアクセスしたときにブラウザに送られてくる小さなテキストデータです。対象サービスは、Cookieを使ってユーザーのアクセスに関する情報を記録しておくことで、次回ユーザーがアクセスする際に、対象サービスをより簡単かつ便利に活用できるようにします。</p>
      <p class="pt-[20px]">(2)Cookieの利用目的</p>
      <p class="pl-[20px] pt-[10px]">＜ユーザーの操作性・利便性の向上＞</p>
      <p class="pl-[20px] pt-[10px]">対象サービスでは、ユーザーのコンピュータを識別し、サービスの利用状況を把握するためにCookieを利用しています。ユーザーのコンピュータにCookieを保存することにより、同じ情報を繰り返し入力する必要がなくなる等、ブラウザで対象サービスにアクセスする際の利便性が向上します。</p>
      <p class="pl-[20px] pt-[10px]">＜分析＞</p>
      <p class="pl-[20px] pt-[10px]">対象サービスでは、ユーザーの対象サービスの利用状況（アクセス状況、トラフィック、ルーティング等）を分析し、対象サービス自体やパフォーマンスの改善、対象サービスからユーザーへ提供するサービスの向上のためにCookieを利用しています。</p>
      <p class="pt-[20px]">(3)Cookieの無効化について</p>
      <p class="pl-[20px]">対象サービスを利用するユーザーは、ブラウザの設定においてCookieの機能を無効にすることができます。この場合は、対象サービスの一部が利用できなくなることがありますので、あらかじめご了承ください。</p>
      
      <h2 class="pt-[50px] text-2xl">免責事項</h2>
      <p class="pt-[20px]">当サイトに掲載されている情報・資料の内容については、万全の注意を払って掲載しておりますが、掲載された情報の正確性を何ら保証するものではありません。従いまして、当サイトに掲載された情報・資料を利用、使用、ダウンロードする等の行為に起因して生じる結果に対し、一切の責任を負いません。 なお、当サイトに掲載された情報の正確性を鑑みた際に、予告なしで情報の変更・削除を行う場合がございますので、予めご了承ください。</p>

      <h2 class="pt-[50px] text-2xl">本プライバシーポリシーの変更</h2>
      <p class="pt-[20px]">当サイトは、法令の制定、改正等により、本ポリシーを適宜見直し、予告なく変更する場合があります。 本ポリシーの変更は、変更後の本ポリシーが当サイトに掲載された時点、またはその他の方法により変更後の本ポリシーが閲覧可能となった時点で有効になります。</p>
    </div>
  </div>

</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>