<template>
  <transition name="fade">
    <div v-if="show">
      <img :src="require('/src/assets/img/gold-log.svg')" 
            alt="logo"
            class="w-full h-[100vh] bg-main_bg">
    </div>

    <div v-else class="bg-base_c" >

  <div class="xs:h-[40vh] md:h-[60vh] lg:h-[80vh] relative">
    <div class="">
      <Carousel :autoplay="3000" :wrapAround="true" :transition="3000">
        <Slide v-for="slide in slides" :key="slide.index">
          <div>
            <img :src="getImgUrl(slide.img)" alt="" class="xs:mt-[80px] sm:mt-[0px] md:h-[100%] md:w-[100%] overflow-auto">
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</div>
  </transition>

</template>

<script setup>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

const slides = [
  { index: 0, img: "top_1.png" },
  { index: 1, img: "top_2.png" },
]

const getImgUrl = (filename) => {
    return process.env.BASE_URL + "TopImage/" + filename;
  };

import { ref } from 'vue'

const show = ref(true)
setTimeout(() => {
  show.value = false
}, 2000)

</script>

<style>
  .fade-enter-active, 
  .fade-leave-active {
   transition: opacity 0.4s;
  }
  .fade-enter, .fade-leave-to {
   opacity: 0;
  }
</style>