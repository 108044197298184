import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
// import VueScrollReveal from 'vue-scroll-reveal'
import router from './router'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import {
  FontAwesomeIcon,
  // FontAwesomeLayers,
  // FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";

library.add(fas, far, fab)

const app = createApp(App)
app.config.globalProperties.$axios = axios;
app
  .use(router)
  // .use(VueScrollReveal)
  .component("font-awesome-icon",FontAwesomeIcon)
  // .component("faLayers",FontAwesomeLayers)
  // .component("faText",FontAwesomeLayersText)
  .mount('#app')
